@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap");
/* TODO:  ADI B. to be replaced with common css file from S3  */
@import url("https://ccom5-storage-dev1-template-assets-eu-west-1.s3.eu-west-1.amazonaws.com/DocumentAssets/styles.css");
/* TODO: ADI B. to update / or delete - classes seem to not work */
/* @import url("https://ccom5-web-dev-bucket83908e77-1u5aaw8sl1pyp/temp/EmailTemplatesCommonStyles.css"); */

* {
  letter-spacing: -0.3px !important;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.custom-popover-40per {
  max-width: 40%;
  box-sizing: border-box;
}

.custom-popover-60per {
  max-width: 60%;
  box-sizing: border-box;
}

.custom-popover-80per {
  max-width: 80%;
  box-sizing: border-box;
}

[class*="custom-popover-"] ul li {
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: block;
}
