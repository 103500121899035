.PlaygroundEditorTheme__ltr {
  text-align: left;
}
.PlaygroundEditorTheme__rtl {
  text-align: right;
}
.PlaygroundEditorTheme__paragraph {
  margin: 0;
  position: relative;
}

.PlaygroundEditorTheme__table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  overflow-y: scroll;
  table-layout: fixed;
  width: 100%;
  margin: 20px 0;
}
.PlaygroundEditorTheme__tableSelected {
  outline: 2px solid rgb(60, 132, 244);
}
.PlaygroundEditorTheme__tableCell {
  border: 1px solid #bbb;
  min-width: 75px;
  vertical-align: top;
  text-align: start;
  padding: 6px 8px;
  position: relative;
  cursor: default;
  outline: none;
}
.PlaygroundEditorTheme__tableCellSortedIndicator {
  display: block;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #999;
}
.PlaygroundEditorTheme__tableCellResizer {
  position: absolute;
  right: -4px;
  height: 100%;
  width: 8px;
  cursor: ew-resize;
  z-index: 10;
  top: 0;
}
.PlaygroundEditorTheme__tableCellHeader {
  background-color: #f2f3f5;
  text-align: start;
}
.PlaygroundEditorTheme__tableCellSelected {
  background-color: #c9dbf0;
}
.PlaygroundEditorTheme__tableCellPrimarySelected {
  border: 2px solid rgb(60, 132, 244);
  display: block;
  height: calc(100% - 2px);
  position: absolute;
  width: calc(100% - 2px);
  left: -1px;
  top: -1px;
  z-index: 2;
}
.PlaygroundEditorTheme__tableCellEditing {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.PlaygroundEditorTheme__tableAddColumns {
  position: absolute;
  top: 0;
  width: 20px;
  background-color: #eee;
  height: 100%;
  right: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.PlaygroundEditorTheme__tableAddColumns:after {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/9/9e/Plus_symbol.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.PlaygroundEditorTheme__tableAddColumns:hover {
  background-color: #c9dbf0;
}
.PlaygroundEditorTheme__tableAddRows {
  position: absolute;
  bottom: -25px;
  width: calc(100% - 25px);
  background-color: #eee;
  height: 20px;
  left: 0;
  animation: table-controls 0.2s ease;
  border: 0;
  cursor: pointer;
}
.PlaygroundEditorTheme__tableAddRows:after {
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/9/9e/Plus_symbol.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.PlaygroundEditorTheme__tableAddRows:hover {
  background-color: #c9dbf0;
}
@keyframes table-controls {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PlaygroundEditorTheme__tableCellResizeRuler {
  display: block;
  position: absolute;
  width: 1px;
  background-color: rgb(60, 132, 244);
  height: 100%;
  top: 0;
}
.PlaygroundEditorTheme__tableCellActionButtonContainer {
  display: block;
  right: 5px;
  top: 6px;
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}
.PlaygroundEditorTheme__tableCellActionButton {
  background-color: #eee;
  display: block;
  border: 0;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  color: #222;
  cursor: pointer;
}
.PlaygroundEditorTheme__tableCellActionButton:hover {
  background-color: #ddd;
}

.TableCellResizer__resizer {
  position: absolute;
}

.TableNode__contentEditable {
  min-height: 20px;
  border: 0px;
  resize: none;
  cursor: text;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0px;
  padding: 0;
  user-select: text;
  font-size: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  z-index: 3;
}

.dropdown {
  z-index: 10;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
  min-width: unset;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important;
  }
  .font-size .dropdown-button-text {
    display: flex !important;
  }
  .code-language .dropdown-button-text {
    display: flex !important;
  }
}

.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

.table-cell-action-button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  background-color: rgb(228, 228, 228);
  cursor: pointer;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Feather-arrows-chevron-down.svg/480px-Feather-arrows-chevron-down.svg.png");
}

.toolbar {
  display: flex;
  margin-bottom: 1px;
  background: #fff;
  padding: 4px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  vertical-align: middle;
  overflow: auto;
  height: 36px;
}

.toolbar button.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  vertical-align: middle;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(223, 223, 223);
}

.toolbar button.toolbar-item.spaced {
  margin: 5px;
  margin-left: 15px;
  margin-top: 15px;
}

.toolbar .toolbar-item:hover:not([disabled]) {
  background-color: #eee;
}

.toolbar .toolbar-item.font-family .text {
  display: block;
  max-width: 40px;
}

.toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: rgb(104, 104, 104);
  text-overflow: ellipsis;
  overflow: hidden;
  height: 20px;
  text-align: left;
}
